/**
 * @generated SignedSource<<9b4092dde7cc0d677616e8b38b74b54e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginChangePasswordInput = {
  clientMutationId?: string | null;
  login: LoginChangePassword;
};
export type LoginChangePassword = {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
};
export type ChangePasswordLoginMutation$variables = {
  input: LoginChangePasswordInput;
};
export type ChangePasswordLoginMutation$data = {
  readonly loginChangePassword: {
    readonly errors: ReadonlyArray<{
      readonly messages: ReadonlyArray<string>;
      readonly path: string;
    }>;
  };
};
export type ChangePasswordLoginMutation = {
  response: ChangePasswordLoginMutation$data;
  variables: ChangePasswordLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginChangePasswordPayload",
        "kind": "LinkedField",
        "name": "loginChangePassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePasswordLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginChangePasswordPayload",
        "kind": "LinkedField",
        "name": "loginChangePassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ddfd4e039725b8b023b8bf2765e7be5",
    "id": null,
    "metadata": {},
    "name": "ChangePasswordLoginMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePasswordLoginMutation(\n  $input: LoginChangePasswordInput!\n) {\n  loginChangePassword(input: $input) {\n    errors {\n      path\n      messages\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7737c4f4b40487855eb8efd57aa07025";

export default node;
